// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: rgba(255,255,255,1);
  @include transition(all .2s);
  @include media-breakpoint-down(md) {
    position: absolute;
  }
  &.scrolled {
    @include transition(all .2s);
  }
  a.logo {
    display: inline-block;
    margin: 10px 0 0 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
