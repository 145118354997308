.content-evidence {
  margin: 30px;
  background: map-get($theme-colors, light);
  padding: 30px;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  img {
    float: left;
    margin-right: 30px;
    max-width: 100%;
    @include media-breakpoint-down(md) {
      float: none;
      margin: 0 0 20px 0;
    }
  }
  .content-text {
    line-height: 2em;
    letter-spacing: .1em;
    padding: 0;
    color: map-get($theme-colors, dark);
  }
}
