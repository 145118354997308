// -------------------------------------------------------------
//   Homepage
//   Homepage specific styles
// -------------------------------------------------------------
.slider-container {
  .container {
    position: relative;
  }
  .swiper-pagination {
    bottom: auto;
    top: 40%;
    left: -20px;
    width: 10px;
    .swiper-pagination-bullet {
      display: block;
      margin: 0 0 10px 0;
    }
    .swiper-pagination-bullet-active {
      background: map_get($theme-colors, secondary);
    }
  }
}
.swiper-home {
  .swiper-slide {
    height: 800px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }
    .effect {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.3);
      z-index: 1;
    }
    .swiper-content {
      position: relative;
      z-index: 2;
      text-align: center;
    }
    h2 {
      font-family: $font-family-serif;
      font-size: 2.5rem;
      color: #fff;
      padding-top: 200px;
      margin-bottom: 40px;
    }
    a {

    }
  }
}
#presentation {
  position: relative;
  margin-top: -100px;
  margin-bottom: 100px;
  @include media-breakpoint-down(md) {
    background: #fff;
  }
  z-index: 3;
  .presentation-block {
    position: relative;
    background: #fff;
    margin-right: 100px;
    padding-left: 50px;
    @include media-breakpoint-down(md) {
      margin-right: 0;
      padding-left: 0;
    }
    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 9999px;
      background-color: #fff;
      margin-right: -1px;
    }
    .text {
      padding: 100px 50px;
      @include media-breakpoint-down(md) {
        padding-bottom: 20px;
      }
    }
    h1 {
      text-transform: uppercase;
      font-size: 1.3rem;
      margin-bottom: 40px;
    }
    h2 {
      font-size: 1.3rem;
      line-height: 2.2rem;
      color: map_get($theme-colors, midlight);
    }
    p {
      color: map_get($theme-colors, medium);
      line-height: 2rem;
    }
    img {
      margin-top: -100px;
      position: relative;
      z-index: 4;
    }
  }
}
#stages-we {
  margin: 0 0 150px 0;
  @include media-breakpoint-down(md) {
    margin: 0 0 40px 0;
  }
  .container {
    position: relative;
  }
  .stages {
    width: 50%;
    position: relative;
    z-index: 1;
    background: map_get($theme-colors, secondary);
    padding: 70px 100px;
    color: #fff;
    @include media-breakpoint-down(md) {
      padding: 30px;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    h2 {
      font-family: $font-family-serif;
      margin-bottom: 40px;
    }
    h3 {
      font-size: 1.2rem;
    }
    a.btn {
      background: #fff;
      color: map_get($theme-colors, secondary);
    }
  }
  .weekends {
    width: 55%;
    position: absolute;
    height: 400px;
    right: 0;
    top: 100px;
    padding: 70px 150px;
    z-index: 2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    color: #fff;
    @include media-breakpoint-down(md) {
      padding: 40px;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      position: static;
      height: auto;
      padding: 50px;
    }
    .effect {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.4);
      z-index: 2;
    }
    .we-content {
      position: relative;
      z-index: 3;
    }
    h2 {
      font-family: $font-family-serif;
      margin-bottom: 30px;
    }
    a.btn {
      background: map_get($theme-colors, tertiary);
      color: #fff;
    }
  }
}
#photos {
  padding: 100px 0;
  overflow: hidden;
  .swiper-container {
    width: 940px;
    margin: 0 auto;
    overflow: visible;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .swiper-slide {
    opacity: .5;
    transform: scale(.9, .9);
    @include transition(all .2s);
    &.swiper-slide-active {
      opacity: 1;
      transform: scale(1,1);
      @include transition(all .2s);
    }
  }

  .swiper-button-next, .swiper-button-prev {
    background: none;
    color: darken(map_get($theme-colors,secondary),20%);
    top: 45%;
    font-size: 4rem;
    width: auto;
    height: auto;
    background: #fff;
    padding: 10px 20px;
  }
  .swiper-button-next {
      right: -20px;
      -webkit-box-shadow: 5px 0px 5px 0px rgba(0,0,0,0.23);
      -moz-box-shadow: 5px 0px 5px 0px rgba(0,0,0,0.23);
      box-shadow: 5px 0px 5px 0px rgba(0,0,0,0.23);
  }
  .swiper-button-prev {
    left: -20px;
    -webkit-box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.23);
    box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.23);
  }
}
