// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------
#contact {
  text-align: center;
  p {
    font-family: $font-family-serif;
    font-size: 1.3rem;
    color: map_get($theme-colors, medium)
  }
}
.main-footer {
  padding: 150px 0 50px 0;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
  .right {
    text-align: right;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  a {
    text-decoration: none;
    line-height: 2rem;
    i {
      color: #29487d;
      font-size: 1.5rem;
      margin-left: 10px;
    }
  }
}
