.view-nos-stages {
  .view-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .stage {
      width: 33.3333333%;
      padding: 10px;
      margin-bottom: 20px;
      margin-top: 20px;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .stage-list {
        .date {
          height: 220px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 120px 0 0 40px;
          color: #fff;
          font-family: $font-family-serif;
          font-size: 2rem;
          position: relative;
          .effect {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.5);
            z-index: 2;
          }
          .stage-content {
            position: relative;
            z-index: 3;
          }
          span {
            color: map_get($theme-colors, secondary);
            display: block;
            margin-top: 10px;
          }
        }
      }
      .date-full {
        font-size: .9rem;
      }
      h2 {
        margin: 20px 0 5px 0;
        color: map_get($theme-colors, midlight);
        font-size: 1.5rem;
        @include media-breakpoint-down(md) {
          font-size: 1.2rem;
        }
      }
      a {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 1rem;
      }
      .description {
        margin-bottom: 10px;
      }
    }
  }
}
.node-type-stage {
  .stage {
    padding: 50px;
  }
  .page-block {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
