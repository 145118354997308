// Variables
//


//
// Color system
//

$theme-colors: (
  primary: #000000,
  secondary: #d2b677,
  tertiary: #6eb0d3,
  success: #28a745,
  info: #17a2b8,
  warning: #ffc107,
  danger: #dc3545,
  light: #f8f9fa,
  midlight: #999999,
  medium: #6b6b6b,
  dark: #343a40
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Quicksand', sans-serif !default;
$font-family-serif: 'PT Serif', sans-serif !default;
