// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	margin-bottom: (1rem / 2);
	color: inherit;
	line-height: 1.1;
	font-family: inherit;
	font-weight: 500;
}
h1,.h1{
	font-size: 2.5rem;
}
h2,.h2 {
	font-size: 2rem;
  font-family: $font-family-serif;
  margin-bottom: 40px;
}
h3,.h3 {
	font-size: 1.75rem;
}
h4,.h4 {
	font-size: 1.5rem;
}
h5,.h5 {
	font-size: 1.25rem;
}
h6,.h6 {
	font-size: 1rem;
}

p {

}

ul {
	li {

	}
}

a {
	color: map-get($theme-colors, primary);
	text-decoration: underline;
	&:hover {
		color: map-get($theme-colors, primary);
		text-decoration: none;
	}
}

.evidence {
  margin: 20px 0;
  padding: 20px 0;
  background: #fff;
  color: #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  text-align: center;
  font-weight: bold;
}
.evidence2 {
  margin: 20px 0;
  padding: 20px 0;
  background: map-get($theme-colors, secondary);
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
