// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------
#nav {
  padding: 30px 0 0 0;
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    text-align: right;
    @include clearfix;

    > li {
      display: inline-block;
      position: relative;
      @include media-breakpoint-down(md) {
        display: block;
      }
      > a,
      > .nolink {
        display: block;
        position: relative;
        padding: 6px 0;
        color: map-get($theme-colors, medium);
        font-size: 0.95rem;
        font-weight: 500;
        line-height: 1.2em;
        text-decoration: none;
        @include transition(padding 0.2s);
      }
      & + li {
        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
        a {
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      &.first {
        a,
        .nolink {
          padding-right: 10px;
        }
      }

      &:hover,
      &.active,
      &.active-trail {
        > a,
        > .nolink {
        }
      }
      &.reservation {
        a {
          background: map_get($theme-colors, secondary);
          color: #fff;
          font-weight: bold;
          border-radius: 20px;
          border: 1px solid map_get($theme-colors, secondary);
          @include transition(all 0.2s);
          &:hover {
            background: #fff;
            color: map_get($theme-colors, secondary);
          }
        }
      }
      @include media-breakpoint-up(lg) {
        &:hover {
          > ul {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      // Niv 2
      > ul {
        position: absolute;
        z-index: 1000;
        top: 100%;
        left: 0;
        margin: 0;
        padding: 0;
        width: 210px;
        min-width: 100%;
        list-style: none;
        text-align: left;
        opacity: 0;
        visibility: hidden;
        @include transition(all 0.35s);

        @include media-breakpoint-down(md) {
          display: none;
          position: relative;
          top: auto;
          left: auto;
          width: 100%;
          background-color: transparentize(#fff, 0.8);
          opacity: 1;
          visibility: visible;
          text-align: center;
        }
        > li {
          > a,
          > .nolink {
            display: block;
            padding: 10px 15px;
            color: #fff;
            background-color: map-get($theme-colors, secondary);
            font-size: 0.9rem;
            line-height: 1.2em;
            text-decoration: none;
            @include transition(all 0.2s);
          }
          & + li {
            border-top: 1px solid transparentize(#fff, 0.85);
          }
          &:hover,
          &.active {
            > a,
            > .nolink {
              background-color: darken(map-get($theme-colors, secondary), 15%);
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    > .sublvl {
      > li {
        &.expanded {
          position: relative;
          overflow: hidden;
        }
        .expand {
          display: block;
          position: absolute;
          z-index: 5;
          top: 0;
          right: 0;
          width: 60px;
          height: 60px;
          &:before {
            content: "+";
            display: block;
            position: absolute;
            top: 10%;
            width: 100%;
            color: map_get($theme-colors, secondary);
            font-size: 24px;
            font-weight: bold;
            line-height: 1em;
            text-align: center;
          }
        }
        &.open {
          .expand {
            &:before {
              content: "-";
            }
          }
          > ul {
            display: block;
          }
        }
      }
    }
  }
}

.main-header.scrolled {
  #nav {
    > ul {
      > li {
        > a,
        > .nolink {
        }
      }
    }
  }
}

.trigger-menu {
  width: 40px;
  height: 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: tranform 0.5s ease-in-out;
  -moz-transition: tranform 0.5s ease-in-out;
  -o-transition: tranform 0.5s ease-in-out;
  transition: tranform 0.5s ease-in-out;
  margin: 15px auto 0 auto;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 10;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: map-get($theme-colors, primary);
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }

  &.open {
    position: fixed;
    top: 30px;
    right: 30px;
    span {
      background: map_get($theme-colors, primary);
      &:nth-child(1) {
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 10px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  nav#nav {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 60px 0;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    background-color: transparentize(#ffffff, 0.02);
    overflow-x: auto;
    overflow-y: scroll;
    @include transition(all 0.35s);
    > ul {
      text-align: center;
      margin-top: 50px;
    }
  }
}

body.open-menu {
  @include media-breakpoint-down(lg) {
    height: 100%;
    overflow: hidden;
    nav#nav {
      visibility: visible;
      opacity: 1;
    }
  }
}
