// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

body {
  background-color: #fff;
  color: #292b2c;
  font-size: 1rem;
  line-height: 1.5em;
}
main#main {
  margin-top: 100px;
}
.page-content {
  position: relative;
  margin-top: -100px;
  .page-block {
    position: relative;
    background: #fff;
    margin-right: 100px;
    padding-left: 50px;
    min-height: 100px;
    z-index: 10;
    @include media-breakpoint-down(md) {
      margin-right: 0;
      padding-left: 0;
      background: #fff;
    }
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 9999px;
      background-color: #fff;
      margin-right: -1px;
    }
  }
}
.iframe-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  padding-top: percentage(9 / 16);
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
ul.pager {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin: 30px 0;
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    a {
      padding: 0 5px;
    }
  }
}
