// -------------------------------------------------------------
//   Banner
// -------------------------------------------------------------
.banner {
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 2;
  .effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    z-index: 2;
  }
  .banner-text {
    position: relative;
    z-index: 3;
    color: #fff;
    text-align: center;
    padding: 120px 0 0 0;
    a {
      color: #fff;
      text-decoration: none;
    }
    h1 {
      font-family: $font-family-serif;
    }
    .breadcrumb {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 800;
      margin-top: 30px;
    }
  }
}
