// -------------------------------------------------------------
//   Paragraphs item galerie
// -------------------------------------------------------------
.content-galerie {
  padding: 100px 0;
  @include media-breakpoint-down(md) {
    overflow: hidden;
    width: 100%;
  }
  .swiper-container {
    width: 940px;
    margin: 0 auto;
    overflow: visible;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .swiper-slide {
    opacity: .5;
    text-align: center;
    transform: scale(.9, .9);
    @include transition(all .2s);
    &.swiper-slide-active {
      opacity: 1;
      transform: scale(1,1);
      @include transition(all .2s);
    }
  }
  .swiper-button-next, .swiper-button-prev {
    background: none;
    color: darken(map_get($theme-colors,secondary),20%);
    top: 45%;
    font-size: 4rem;
    width: auto;
    height: auto;
    background: #fff;
    padding: 10px 20px;
  }
  .swiper-button-next {
    right: -20px;
    -webkit-box-shadow: 5px 0px 5px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: 5px 0px 5px 0px rgba(0,0,0,0.23);
    box-shadow: 5px 0px 5px 0px rgba(0,0,0,0.23);
  }
  .swiper-button-prev {
    left: -20px;
    -webkit-box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.23);
    -moz-box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.23);
    box-shadow: -5px 0px 5px 0px rgba(0,0,0,0.23);
  }
}
